"use client"
import { useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Modal, Button } from 'react-daisyui';
import Webcam from 'react-webcam';
import axios from "axios";

const CameraModal = forwardRef(({ modalOpen, setAvatarCaption, speak, cameraEnable, handleOpenCamera }, ref) => {
    const webcamRef = useRef(null);


    const videoConstraints = {
        width: 1280,
        height: 720,
    };

    const capture = useCallback(
        async () => {
            const imageSrc = await webcamRef.current.getScreenshot();
            const apiBase = "https://octo-gpt4-vision-westus.openai.azure.com/";
            const deploymentName = "gpt-4-vision";
            const apiKey = "dc87ca87c5d94465bbe3e8267bf57753";

            const baseUrl = `${apiBase}openai/deployments/${deploymentName}`;
            const headers = {
                "Content-Type": "application/json",
                "api-key": apiKey
            };

            const endpoint = `${baseUrl}/chat/completions?api-version=2023-12-01-preview`;
            const data = {
                "messages": [
                    { "role": "system", "content": "You are a helpful assistant." },
                    {
                        "role": "user",
                        "content": [
                            { "type": "text", "text": "Use the name on the name tag to greet the user." },
                            {
                                "type": "image_url",
                                "image_url": { "url": imageSrc },
                            },
                        ],
                    },
                ],
                "max_tokens": 200,
            };

            setAvatarCaption({ role: "user", content: `Describe this image...` });

            axios.post(
                endpoint,
                data,
                {
                    headers: headers,
                }
            ).then((response) => {
                speak(response.data.choices[0].message.content);
            }
            ).catch((error) => {
                console.log(error)
            })
        },
        [webcamRef, setAvatarCaption, speak]
    );

    useImperativeHandle(ref, () => ({
        capture
    }));


    return (
        <Modal open={modalOpen}>
            <Modal.Header>
                <h3>Camera</h3>
            </Modal.Header>
            <Modal.Body>
                {cameraEnable && <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}

                />}
            <form method="dialog">
                <Button size="sm" color="ghost" shape="circle" className="absolute right-2 top-2" onClick={handleOpenCamera}>
                    x
                </Button>
                <Button onClick={capture}>Capture photo</Button>
            </form>
            </Modal.Body>
        </Modal>

    )
}
)
export default CameraModal;
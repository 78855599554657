import React from "react";
import { Drawer, Menu, Select } from "react-daisyui";

const ConfigDrawer = ({
    drawerStatus,
    handleDrawerOpen,
    speaker1Language,
    setSpeaker1Language,
    speaker2Language,
    setSpeaker2Language
}) => {


    return (
        <Drawer
            open={drawerStatus}
            side={
                <Menu className="p-2 w-80 h-full bg-base-200 text-base-content z-50">
                    <Menu.Title>Configuration</Menu.Title>
                    <Menu.Item className="py-2">
                        Speaker 1 Langauge:
                        <Select value={speaker1Language} onChange={event => setSpeaker1Language(event.target.value )} color="primary">
                            <option value={'English'}>English</option>
                            <option value={'Spanish'}>Spanish</option>
                            <option value={'French'}>French</option>
                            <option value={'German'}>German</option>
                            <option value={'Simplified Chineese'}>Simplified Chineese</option>
                            <option value={'Arabic'}>Arabic</option>
                            <option value={'Japanese'}>Japanese</option>
                            <option value={'Portuguese'}>Portuguese</option>
                            <option value={'French Canadian'}>French Canadian</option>
                        </Select>
                    </Menu.Item>
                    <Menu.Item className="py-2">
                        Speaker 2 Langauge:
                        <Select value={speaker2Language} onChange={event => setSpeaker2Language(event.target.value )} color="accent">
                            <option value={'English'}>English</option>
                            <option value={'Spanish'}>Spanish</option>
                            <option value={'French'}>French</option>
                            <option value={'German'}>German</option>
                            <option value={'Simplified Chineese'}>Simplified Chineese</option>
                            <option value={'Arabic'}>Arabic</option>
                            <option value={'Japanese'}>Japanese</option>
                            <option value={'Portuguese'}>Portuguese</option>
                            <option value={'French Canadian'}>French Canadian</option>
                        </Select>
                    </Menu.Item>
                </Menu>
            }
            onClickOverlay={handleDrawerOpen}
            className="z-50"
        ></Drawer>
    );
};

export default ConfigDrawer;
